<template>
  <div class="container" style="margin-top:150px;">
    <div class="va-card ">
      <div style="parding:10px; margin:40px;">
        <div class="row">
          <div class="col-lg-12">
            <div class="header_section">
              <div class="">
                <button class="btn btn-default custom_btn" data-target="#create_modal" data-toggle="modal">
                  <i class="fa fa-plus"></i> New Merchant Groups
                </button>

                <router-link :to="{ name: 'merchants'}">
                  <button class="btn ml-4 btn-default custom_btn" >
                    Merchants List <i class="glyphicon glyphicon-arrow-right"></i>
                  </button>
                </router-link>
              </div>

              <!-- modal for creating groups -->
              <div class="modal fade" id="create_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <form @submit.prevent="createMerchantGroup()">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Create Group</h5>
                        <button id="close_btn" type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="form-group">
                          <label>Group Name: </label>
                          <div>
                            <input type="text" class="form-control" v-model="groupName" />
                          </div>
                        </div>

                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Save</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- end modal -->

            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="table_section">
            <table class="table  table-hover">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Merchants</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr v-for="(group, gindex) in groups" :key="gindex">
                  <td>
                    {{group.name}}
                  </td>
                  <td>
                    <div v-if="group.merchants && group.merchants.length > 0">
                      <span v-for="(merchant, mindex) in group.merchants" :key="merchant.id"> {{mindex+1}} . {{merchant.name}} <span v-if="mindex+1 < group.merchants.length">, &nbsp;</span></span>
                    </div>
                    <div v-else>
                      N/A
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="!loading">
                <tr>
                  <td colspan="8">
                    <div id="pagination" v-if="totalPages > 1">
                      <ul class="showItems">
                        <li>Show Items:
                          <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                            <option :value="10">10</option>
                            <option :value="20">20</option>
                            <option :value="30">30</option>
                            <option :value="40">40</option>
                            <option :value="50">50</option>
                          </select>
                        </li>

                      </ul>
                      <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />

                    </div>

                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div v-if="loading" class="loading-cover">
            <div class="loader"> </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { useToast } from "vue-toastification";
const toast = useToast();
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import useVuelidate from '@vuelidate/core';
import Pagination from '@/pages/pagination/pagination.vue';

export default {
    components: {
        Pagination,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            groups: [],
            page: 1,
            totalPages: 0,
            totalRecords: 0,
            recordsPerPage: 10,
            loading: false,
            groupName: '',
            errors: false,
            errorMsg: [],
            enterpageno: '',
            spinner: false
            // spinner: "<i class='fa fa-spinner fa-spin '></i> Loggin in...",
        }
    },
    created: async function () {
        this.getMerchantGroups()
    },
    methods: {
        async getMerchantGroups() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/merchant-groups?size=${this.recordsPerPage}`;
            this.loading = true;
            this.axios.get(url,config).then((response) => {
                if (response.status === 200) {
                    this.groups = response.data.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            }).finally(()=>{
                this.loading = false;
            })

        },
        async createMerchantGroup() {
            // 
            const url = `${this.dashBaseUrl}/create-groups`;
            const payload = {
                'name': this.groupName
            };
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.post(url, payload, config).then((response) => {
                if (response.status === 200) {
                    toast.success('Group created successfully');
                    this.getMerchantGroups();
                }
            }).catch((error) => {
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors
                } else if (error.response.status === 401) {
                    toast.error('session has expired');
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() =>{
                 this.spinner = false;
                  document.getElementById('close_btn').click()
            });
        },
        onPageChange(page) {
            this.page = page
            this.getMerchantGroups();
        },
        onChangeRecordsPerPage() {
            this.getMerchantGroups();
            // ds
        },

    },
    mixins: [ReusableDataMixins]
}
</script>
